// import logo from './logo.svg';
import './App.css';
import Row from './Row.js';
import React, { useState, useEffect } from "react";

function App() {
  const searchParams = new URLSearchParams(window.location.search)
  const initValues = JSON.parse(searchParams.get('data'))
  const [myName, setMyName] = useState(initValues?.myName || '')

  function handleChangeMyName(e) {
    setMyName(e.target.value)
  }

  useEffect(() => {
    let params = new URLSearchParams(window.location.search)
    params.set('data', JSON.stringify({myName}))
    window.history.replaceState(null, "", "?"+params.toString())
  }, [myName])
  
  return (
    <div className="App bg-gradient-to-r from-purple-200 to-pink-200 pb-[4rem]">
      <h1 className="px-1 py-1 text-xl font-bold text-left">
        Eurovision 24 scorecard
      </h1>
      <div className='flex justify-start'>
        <input className="text-xl px-1 py-1 bg-pink-100/0" type="text" id="myName" onChange={handleChangeMyName} value={myName} placeholder="Namn" name="myName"/>
      </div>
      <div className='text-left px-1 text-slate-400'>
        <p className='text-xs front-bold'>My score: 1, 2, 3, 4, 5, 6, 7, 8, 10, 12</p>
        <p className='text-xs front-bold'>My ranking: 1-25</p>
        <p className='text-xs front-bold'>Guess final result: 1-25</p>
        <p className='text-xs front-bold'>Final result: 1-25</p>
      </div>
      <table className='px-1 mr-[2rem]'>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th className='rotate'>
              <div className='text-xs'>
                <span>My score</span>
              </div>
            </th>
            <th className='rotate'>
              <div className='text-xs'>
                <span>My ranking</span>
              </div>
            </th>
            <th className='rotate'>
              <div className='text-xs'>
                <span>Guess final result</span>
              </div>
            </th>
            <th className='rotate'>
              <div className='text-xs'>
                <span>Final result</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <Row number="1" finalResult={9} prefix="🇸🇪" id="1swe" name="Sweden" artist="Marcus & Martinus" song="Unforgettable"></Row>
          <Row number="2" finalResult={3} prefix="🇺🇦" id="2ukr" name="Ukraine" artist="alyonaalyona & JerryHeil" song="Teresa & Maria"></Row>
          <Row number="3" finalResult={12} prefix="🇩🇪" id="3ger" name="Germany" artist="ISAAK" song="Always On The Run"></Row>
          <Row number="4" finalResult={13} prefix="🇱🇺" id="4lux" name="Luxembourg" artist="TALI" song="Fighter"></Row>
          <Row number="5" finalResult={5} prefix="🇮🇱" id="5isr" name="Israel" artist="Eden Golan" song="Hurricane"></Row>

          <Row number="6" finalResult={14} prefix="🇱🇹" id="6lit" name="Lithuania" artist="Silvester Belt" song="Luktelk"></Row>
          <Row number="7" finalResult={22} prefix="🇪🇸" id="7isr" name="Spain" artist="Nebulossa" song="ZORRA"></Row>
          <Row number="8" finalResult={20} prefix="🇪🇪" id="8est" name="Estonia" artist="5MIINUST x Puuluup" song="(nendest)narkootikumi..."></Row>
          <Row number="9" finalResult={6} prefix="🇮🇪" id="9irl" name="Ireland" artist="BambieThug" song="Doomsday Blue"></Row>
          <Row number="10" finalResult={16} prefix="🇱🇻" id="10lat" name="Latvia" artist="Dons" song="Hollow"></Row>

          <Row number="11" finalResult={11} prefix="🇬🇷" id="11gre" name="Greece" artist="Marina Satti" song="ZARI"></Row>
          <Row number="12" finalResult={18} prefix="🇬🇧" id="12uk" name="Uk" artist="Olly Alexander" song="Dizzy"></Row>
          <Row number="13" finalResult={25} prefix="🇳🇴" id="13nor" name="Norway" artist="Gåte" song="Ulveham"></Row>
          <Row number="14" finalResult={7} prefix="🇮🇹" id="14ita" name="Italy" artist="Angelina Mango" song="La Noia"></Row>
          <Row number="15" finalResult={17} prefix="🇷🇸" id="15ser" name="Serbia" artist="TEYA DORA" song="RAMONDA"></Row>

          <Row number="16" finalResult={19} prefix="🇫🇮" id="16fin" name="Finland" artist="Windows95man" song="No Rules!"></Row>
          <Row number="17" finalResult={10} prefix="🇵🇹" id="17por" name="Portugal" artist="iolanda" song="Grito"></Row>
          <Row number="18" finalResult={8} prefix="🇦🇲" id="18arm" name="Armenia" artist="LADANIVA" song="Jako"></Row>
          <Row number="19" finalResult={15} prefix="🇨🇾" id="19cyp" name="Cyprus" artist="Silia Kapsis" song="Liar"></Row>
          <Row number="20" finalResult={1} prefix="🇨🇭" id="20swi" name="Switzerland" artist="Nemo" song="The Code"></Row>

          <Row number="21" finalResult={23} prefix="🇸🇮" id="21slo" name="Slovenia" artist="Raiven" song="Veronika"></Row>
          <Row number="22" finalResult={2} prefix="🇭🇷" id="22cro" name="Croatia" artist="Baby Lasagna" song="RimTimTagiDim"></Row>
          <Row number="23" finalResult={21} prefix="🇬🇪" id="23geo" name="Georgia" artist="Nutsa Buzaladze" song="Firefighter"></Row>
          <Row number="24" finalResult={4} prefix="🇫🇷" id="24fra" name="France" artist="Slimane" song="Mon Amour"></Row>
          <Row number="25" finalResult={24} prefix="🇦🇹" id="25aus" name="Austria" artist="Kaleen" song="We Will Rave"></Row>


        </tbody>
      </table>
    </div>
  );
}

export default App;
