import React, { useState, useEffect } from "react";

export default function Row({ prefix, name, id, number, artist, song, finalResult }) {
  // const initValues = JSON.parse(localStorage.getItem(id))
  const searchParams = new URLSearchParams(window.location.search);
  // let decodedObject = decodeURIComponent(queryParams);
  // console.log("=====>", searchParams, searchParams.get(id), JSON.parse(searchParams.get(id)))

  const initValues = JSON.parse(searchParams.get(id))

  const [myScore, setMyScore] = useState(initValues?.s || '')
  const [myRanking, setMyRanking] = useState(initValues?.r || '')
  const [guessResult, setGuessResult] = useState(initValues?.gr || '')
  // const [finalResult, setFinalResult] = useState(initValues?.fr || '')
  
  function handleChangeMyScore(e) {
    setMyScore(e.target.value)
  }

  function handleChangeMyRanking(e) {
    setMyRanking(e.target.value)
  }

  function handleChangeGuessResult(e) {
    setGuessResult(e.target.value)
  }

  // function handleChangeFinalResult(e) {
  //   setFinalResult(e.target.value)
  // }

  useEffect(() => {
    // localStorage.setItem(id, JSON.stringify({
    //   myScore,
    //   myRanking,
    //   guessResult,
    //   finalResult
    // }))
    let params = new URLSearchParams(window.location.search)
    params.set(id, JSON.stringify({s: myScore, r: myRanking,
      gr: guessResult,
      // fr: finalResult
    }))
    window.history.replaceState(null, "", "?"+params.toString())
  }, [myScore, myRanking, guessResult, finalResult, id])
  
  return (
    <tr className="">
      <td>
        <div className="border border-2 border-black/20 rounded-full w-6 h-6 max-w-6 max-h-6 flex justify-center content-center text-sm">{number}</div>
      </td>
      <td>
        <p className="text-2xl px-1">{prefix}</p>
      </td>
      <td className="text-left">
        <p>{name}</p>
        <p className="text-xs -mt-1">
          <span className="text-slate-400">{artist} - </span> <i className="text-slate-400">{song}</i>
        </p>
      </td>
      <td className="px-0.5">
        <input className="border border-dashed border-black rounded text-center bg-white/30" type="number" id={id+"-myScore"} onChange={handleChangeMyScore} value={myScore} name="myScore" min="0" max="12" />
      </td>
      <td className="px-0.5">
        <input className="border border-dotted border-black rounded text-center bg-white/30" type="number" id={id+"-myRanking"} onChange={handleChangeMyRanking} value={myRanking} name="myRanking" min="0" max="25" />
      </td>
      <td className="px-0.5">
        <input className="border border-solid border-black rounded text-center bg-white/30" type="number" id={id+"-guessResult"} onChange={handleChangeGuessResult} value={guessResult} name="guessResult" min="0" max="25" />
      </td>
      <td className="px-0.5">
        <input className="border border-double border-black rounded text-center bg-white/30" type="number" id={id+"-finalResult"} value={finalResult} disabled={true} name="finalResult" min="0" max="25" />
      </td>
    </tr>
  );
}